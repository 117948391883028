import React from 'react'
import {Formik, Form, Field, ErrorMessage} from 'formik'
import * as Yup from 'yup'

export const MailForm = () => {
	const [formSubmitted, setFormSubmitted] = React.useState(false)

	const initialValues = {
		firstName: '',
		lastName: '',
		email: '',
		phoneNumber: ''
	}

	const validationSchema = Yup.object({
		firstName: Yup.string().required('Informe o seu nome'),
		lastName: Yup.string().required('Informe o seu sobrenome'),
		email: Yup.string().email('Email inválido').required('Informe o seu email'),
		phoneNumber: Yup.string()
			.matches(/^\d+$/, 'Apenas números')
			.required('Informe o seu telefone')
	})

	const handleSubmit = async (values, {setSubmitting, resetForm}) => {
		try {
			const endpoint = `${process.env.REACT_APP_SEND_EMAIL_ENDPOINT}/send-lead-email`
			const response = await fetch(endpoint, {
				method: 'POST',
				headers: {'Content-Type': 'application/json'},
				body: JSON.stringify(values)
			})

			if (!response.ok) throw new Error('Erro ao enviar')

			alert(
				'Obrigado! Dados enviados com sucesso! Entraremos em contato em até 24 horas.'
			)
			resetForm()
		} catch (error) {
			alert('Erro ao enviar o formulário. Tente novamente.')
		} finally {
			setFormSubmitted(false)
			setSubmitting(false)
		}
	}

	return (
		<Formik
			initialValues={initialValues}
			validationSchema={validationSchema}
			onSubmit={handleSubmit}
		>
			{({isSubmitting, submitForm, errors}) => (
				<Form className="frame1-home-form">
					<div className="frame1-home-row4">
						<div className="frame1-home-input">
							<span className="frame1-home-text095 14Regular">
								<span>Nome</span>
							</span>
							<Field
								type="text"
								name="firstName"
								className="frame1-home-input1"
							/>
							{formSubmitted && Boolean(errors.firstName) && (
								<ErrorMessage
									name="firstName"
									component="div"
									className="error-message"
								/>
							)}
						</div>
						<div className="frame1-home-input2">
							<span className="frame1-home-text097 14Regular">
								<span>Sobrenome</span>
							</span>
							<Field
								type="text"
								name="lastName"
								className="frame1-home-input3"
							/>
							{formSubmitted && Boolean(errors.lastName) && (
								<ErrorMessage
									name="lastName"
									component="div"
									className="error-message"
								/>
							)}
						</div>

						<div className="frame1-home-input4">
							<span className="frame1-home-text099 14Regular">
								<span>Email</span>
							</span>
							<Field type="email" name="email" className="frame1-home-input5" />
							{formSubmitted && Boolean(errors.email) && (
								<ErrorMessage
									name="email"
									component="div"
									className="error-message"
								/>
							)}
						</div>

						<div className="frame1-home-input6">
							<span className="frame1-home-text101 14Regular">
								<span>Telefone</span>
							</span>
							<Field
								type="text"
								name="phoneNumber"
								className="frame1-home-input7"
							/>
							{formSubmitted && Boolean(errors.phoneNumber) && (
								<ErrorMessage
									name="phoneNumber"
									component="div"
									className="error-message"
								/>
							)}
						</div>
					</div>

					<button
						type="button"
						className="frame1-home-primary-button"
						disabled={isSubmitting}
						onClick={() => {
							setFormSubmitted(true)
							submitForm()
						}}
						style={{
							cursor: 'pointer'
						}}
					>
						{isSubmitting ? 'ENVIANDO...' : 'ENVIAR'}
					</button>
				</Form>
			)}
		</Formik>
	)
}
